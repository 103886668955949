<template>
  <v-container>
    {{ message }}
    <v-form @submit.prevent="sendForm()">
      <form-field
          v-for="field in form.form_fields"
          :key="field.key"
          :label="field.label"
          :type="field.type"
          :required="field.is_required"
          :values="field.values"
          v-model="fieldsData[field.key]"
      />
    </v-form>

    <v-btn block color="accent" dark @click="sendForm">Versturen</v-btn>
  </v-container>
</template>

<script>
import FormField from './FormField';

export default {
  components: { FormField },
  name: 'MailForm',
  props: {
    form: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      valid: null,
      fieldsData: {},
      message: '',
    };
  },
  methods: {
    sendForm() {
      this.$http.post('email-form/' + this.form.id + '/send', this.fieldsData)
          .then((response) => {
            this.message = response.data.message;
            for (let index in this.fieldsData) {
              this.fieldsData[index] = null;
            }
          }).catch((response) => {
        this.message = response.data.message;
        response.data.errors.forEach((error) => {
          this.message += error + ' ';
        });
      });
    },
  },
};
</script>

<style scoped>

</style>
