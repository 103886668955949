<template>
  <div class="page page-text">
    <h1>{{ page.title }}</h1>
    <span class="history-back-btn" @click="$router.go(-1)">&lt; Terug</span>
    <div class="intro" v-html="intro"></div>

    <div v-html="page.content" v-for="(page, index) in texts" :key="index"></div>
    <mail-form :form="page.email_form"/>
  </div>
</template>

<script>
import MailForm from '../components/MailForm';

export default {
  components: { MailForm },
  name: 'page',
  data() {
    return {
      page: {},
    };
  },
  computed: {
    texts() {
      let texts = [...this.page.texts];
      texts.splice(0, 1);
      return texts;
    },
    intro() {
      if (typeof this.page.texts !== 'undefined') {
        return this.page.texts[0].content;
      }
      return '';
    },
  },
  created() {
    this.$http.get('page/' + this.$route.params.pageId)
        .then((response) => {
          this.page = response.data.data;
        });
  },
};
</script>
